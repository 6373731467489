import { useState } from 'react';
import { IonLabel, IonButton, IonRadioGroup, IonItem, IonRadio } from '@ionic/react';
import { modalToggleStore } from '../../../store/Store';
import '../../../theme/Home.css';

const ApplyPermit0 = () => {
    const [confirm, setConfirm] = useState(false);
    const openSecondPage = () => {
        modalToggleStore.update((s) => {
            s.permitPage = 1;
        });
    };

    const clickAgree = (e) => {
        setConfirm(e.detail.value);
    };
    return (
        <>
            <h3 className='ion-padding terms-title'>
                Is your outdoor fireplace installed and are you ready to light your first fire?
            </h3>
            <div className='ion-padding-horizontal'>
                <p className='streets-blurb'>
                    Before proceeding, your outdoor fireplace must be installed and ready to use as
                    an inspection is required prior to granting your permit.
                </p>
            </div>
            <div className='ion-padding-horizontal'>
                <p className='streets-blurb'>
                    I confirm that my outdoor fireplace is installed and ready to use.
                </p>
            </div>
            <IonRadioGroup className='permit-type-item' value={confirm} onIonChange={clickAgree}>
                <IonItem>
                    <IonLabel className='ion-text-wrap'>
                        <strong>Yes</strong>, my outdoor fireplace is installed and ready for
                        inspection.
                    </IonLabel>
                    <IonRadio color='tertiary' slot='start' value={true} />
                </IonItem>
                <IonItem>
                    <IonLabel className='ion-text-wrap'>
                        <strong>No</strong>, my outdoor fireplace is not yet ready for inspection.
                    </IonLabel>
                    <IonRadio color='tertiary' slot='start' value={false} />
                </IonItem>
            </IonRadioGroup>
            <br />
            {!confirm && (
                <div className='ion-padding-horizontal'>
                    <p>
                        Please contact the Parry Sound Fire Department 705-746-2262 and ask for a{' '}
                        <u>(no cost to you)</u> Outdoor Fireplace Inspection.{' '}
                        <strong>Do not purchase a fire permit until you are ready to burn.</strong>
                    </p>
                </div>
            )}
            {confirm && (
                <IonButton
                    className='ion-padding-horizontal'
                    shape='round'
                    expand='block'
                    color='tertiary'
                    disabled={!confirm}
                    onClick={openSecondPage}
                >
                    Continue
                </IonButton>
            )}
        </>
    );
};

export default ApplyPermit0;
