import { IonButton, IonRow, IonCol, IonGrid } from '@ionic/react';
import ProfileModal from './ProfileModal';
import '../../theme/Home.css';
import { useEffect, useState } from 'react';
import { profileFormStore, appStore, toggleStore, modalToggleStore } from '../../store/Store';
import RiskWidget from '../common/RiskWidget';
import WeatherWidget from '../common/WeatherWidget';
import AnnouncementWidget from '../common/AnnouncementWidget';
import ContactWidget from '../common/ContactWidget';
import BurnBan from './BurnBan';
import EndOfYearWarning from './EndOfYearWarning';
import PermitModal from './PermitModal';

function ApplyPermit() {
    const resetEmailCountDown = appStore.useState((s) => s.resetEmailCountDown);
    const permitFormToggle = toggleStore.useState((s) => s.permitFormToggle);
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);
    const userInfo = appStore.useState((s) => s.userInfo);
    const [modalOpen, setModalOpen] = useState(false);
    const openProfileModal = () => {
        modalToggleStore.update((s) => {
            s.profilePage = 1;
        });
    };
    useEffect(() => {
        if (resetEmailCountDown > 0) {
            setTimeout(() => {
                appStore.update((s) => {
                    s.resetEmailCountDown = resetEmailCountDown - 1;
                });
            }, 1000);
        } else {
            toggleStore.update((s) => {
                s.sendVerificationEmail = false;
            });
        }
    }, [resetEmailCountDown]);

    const openPermitForm = () => {
        console.log('open permit form');
        setModalOpen(true);
    };

    // const sendResetPwEmail = () => {
    //     resendEmailVerification();
    //     appStore.update((s) => {
    //         s.resetEmailCountDown = 60;
    //     });

    //     toggleStore.update((s) => {
    //         s.sendVerificationEmail = true;
    //     });
    // };

    // commented out so that non-email-verified user can still apply for permit

    // if (!emailVerified) {
    //     return (
    //         <>
    //             An email verification has been sent to your inbox. Please follow its instructions to
    //             verify your email first before you can access your account. <br /> <br />
    //             If you haven't receive the email, you can have it sent again by clicking the button
    //             below. Note: it may take 3-5 minutes for the verification email to arrive.
    //             <br />
    //             <IonButton disabled={sendVerificationEmail} onClick={sendResetPwEmail}>
    //                 {sendVerificationEmail && `Send Verification Email  ${resetEmailCountDown}`}
    //                 {!sendVerificationEmail && 'Send Verification Email'}
    //             </IonButton>
    //         </>
    //     );
    // } else
    if (userInfo.address) {
        return (
            <>
                <PermitModal setModalOpen={setModalOpen} modalOpen={modalOpen} />
                <br />

                {settingsForm.EOYWarningToggle && <EndOfYearWarning />}
                {settingsForm.burnBanToggle && <BurnBan />}
                {userInfo.phone && !permitFormToggle && !settingsForm.burnBanToggle && (
                    <div className='apply-permit-btn'>
                        <h1>Apply for a permit using the button below</h1>
                        <IonButton
                            color='success'
                            shape='round'
                            size='large'
                            onClick={openPermitForm}
                        >
                            Apply for a permit
                        </IonButton>
                    </div>
                )}
                <br />
                <IonGrid className='widget-grid'>
                    <IonRow>
                        {settingsForm.riskToggle && !permitFormToggle && (
                            <IonCol size-sm='12' size-md='12' size-lg='6'>
                                {' '}
                                <RiskWidget />
                            </IonCol>
                        )}

                        {settingsForm.weatherToggle && !permitFormToggle && (
                            <IonCol size-sm='12' size-md='12' size-lg='6'>
                                <WeatherWidget />
                            </IonCol>
                        )}
                    </IonRow>
                    <br />
                    <IonRow>
                        {' '}
                        {settingsForm.announcementsToggle && !permitFormToggle && (
                            <IonCol size='12'>
                                <AnnouncementWidget />
                            </IonCol>
                        )}{' '}
                    </IonRow>
                    <IonRow>
                        {settingsForm.contactToggle && !permitFormToggle && (
                            <IonCol size='12'>
                                <ContactWidget />
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
            </>
        );
    } else {
        return (
            <>
                <ProfileModal />
                <br />
                {!userInfo.address && !permitFormToggle && (
                    <div className='account-card ion-margin-bottom'>
                        <h1>Please complete your profile before applying for a permit</h1>
                        <IonButton
                            color='success'
                            shape='round'
                            className=' ion-margin-vertical'
                            onClick={openProfileModal}
                            size='large'
                        >
                            Complete your profile
                        </IonButton>
                    </div>
                )}
                <IonGrid className='widget-grid'>
                    <IonRow>
                        {settingsForm.riskToggle && !permitFormToggle && (
                            <IonCol size-sm='12' size-md='12' size-lg='6'>
                                <RiskWidget />
                            </IonCol>
                        )}

                        {settingsForm.weatherToggle && !permitFormToggle && (
                            <IonCol size-sm='12' size-md='12' size-lg='6'>
                                <WeatherWidget />
                            </IonCol>
                        )}
                        {settingsForm.announcementsToggle && !permitFormToggle && (
                            <IonCol size='12'>
                                <AnnouncementWidget />
                            </IonCol>
                        )}
                        {settingsForm.contactToggle && !permitFormToggle && (
                            <IonCol size='12'>
                                <ContactWidget />
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
            </>
        );
    }
}

export default ApplyPermit;
